.Payments h1 {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
    text-align: left;
    padding: 1rem;
    border-bottom: solid lightgray 1px;
}

.Payments {
    font-family: 'Oxanium', sans-serif;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    text-align: center;
    padding: 20px;
    height: 530px;
}

@media (max-width: 767px) {
    .Payments {
      font-size: 12px; /* Příklad: Změna velikosti písma na menší hodnotu */
      /* Další úpravy stylu pro menší obrazovky */
    }
    .Payments h4 {
        font-size: 10px;
    }
  }