.Header-stack h1 {
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
    margin: 0 20px;
    font-size: 4rem;
    text-decoration: underline;
}

.Header-button {
    margin: 0 20px;
}